export default [
  {
    url: "/supplier/insights",
    name: "Insights",
    icon: "BarChart2Icon",
    slug: "insights",
    i18n: "Insights"
  },
  {
    url: "/supplier/insights-v2",
    name: "Insights v2",
    icon: "BarChart2Icon",
    slug: "insights v2",
    i18n: "Insights v2"
  }
];
